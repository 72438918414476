<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Branch
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>
                      <v-col cols="12" md="4" class="text-center">
                        <v-img style="cursor:pointer" :src="imglinks" @click="$refs.foto_profile.click()"></v-img>
                        <span style="color:red">Image max 300Kb</span>
                      </v-col>
                      <v-col cols="12" md="8">
                          <v-text-field
                          v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="kode"
                          :rules="nameRules"
                          label="Code"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="phone"
                          :rules="phoneRules"
                          label="Phone"
                          required
                          ></v-text-field>
                          <v-autocomplete
                          v-model="brand"
                          :items="brands"
                          chips
                          label="Brand"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('brand')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('brand',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('brand',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                          v-model="karyawan"
                          :items="data_manager"
                          chips
                          label="Manager"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('karyawan')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('karyawan',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('karyawan',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          ></v-text-field>

                          <v-textarea
                            v-model="address"
                            label="Address"
                          ></v-textarea>
                          <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                      </v-col>
                    </v-row>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  methods: {
    send_data() {
      const data = new FormData()
      data.append('phone', this.phone)
      data.append('photos', this.foto)
      data.append('email', this.email)
      data.append('address', this.address)
      data.append('note', this.note)
      data.append('kode', this.kode)
      data.append('name', this.name)
      data.append('brand_id', this.brand)
      data.append('karyawan_id', this.karyawan)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/branch/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('branch','branchs')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/branch/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('branch','branchs')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.phone = ''
      this.foto = ''
      this.email = ''
      this.address = ''
      this.note = ''
      this.karyawan = ''
      this.name = ''
      this.imgurl = 'img/no_img.png'
      this.brand = ''
      this.brand = ''
    },
    remove(data) {
      this[data] = ''
    },
    img(folder = null ,file = null) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    phone: '',
    kode: '',
    karyawan: '',
    data_manager:[],
    brand: '',
    email: '',
    address: '',
    password: '',
    phoneRules: [
      (v) => !v || /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(v) || 'Please enter a valid Phone number (ex. 0857XXXXXX)'
    ],
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    nameRules: [
      v => !!v || 'Required field'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    ...mapGetters({
      brands: 'data/brands',
      employees: 'data/employees'
    }),
    imglinks: function () {
      if (this.edit_data) {
        if (this.edit_data.image_link) {
          return process.env.VUE_APP_IMGLINK + 'branch/' + this.edit_data.image_link
        } else {
          return this.imgurl
        }
      } else {
        return this.imgurl
      }
    }

  },
  async created () {
    if (this.edit_data) {
         this.phone = this.edit_data.phone
          this.email = this.edit_data.email
          this.address = this.edit_data.address
          this.note = this.edit_data.note
          this.name = this.edit_data.name
          this.kode = this.edit_data.kode
          this.karyawan = this.edit_data.karyawan_id
          this.brand = this.edit_data.brand_id
    }
       await this.get_data_master('karyawan','employees')
        await this.get_data_master('brand','brands')
      // this.data_manager = await this.employees.map(x => {
      //   if (x.jabatan_id > 0) {
      //     if ( x.jabatan.name.toLowerCase() == 'manager') {
      //     return x

      //     }
      //   }
      // })
      this.employees.forEach(element => {
        if (element.jabatan !== null &&  element.jabatan.name.toLowerCase() == 'manager') {
          this.data_manager.push(element)
        }
      });
      console.log(this.data_manager)
  }
}
</script>
